<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2y"
            defaultEnd="2M"
            label="공포기간"
            name="promulgationPeriod"
            v-model="searchParam.promulgationPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        </div>
      </template>
    </c-search-box>
    <c-table
      title="법령 목록"
      tableId="safetyControlDangerous"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row.lawLink">
          <q-btn
            class="tableinnerBtn"
            flat
            icon="launch"
            align="center"
            color="blue-6"
            label=""
            @click.stop="linkClick(props, col)" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-control-dangerous',
  data() {
    return {
      searchParam: {
        promulgationPeriod: [],
      },
      grid: {
        columns: [
          {
            name: 'lawSerialNum',
            field: 'lawSerialNum',
            label: '법령일련번호',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'curHistroyCode',
            field: 'curHistroyCode',
            label: '현행연혁코드',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'lawNameKor',
            field: 'lawNameKor',
            label: '법령명한글',
            align: 'left',
            sortable: false,
            style: 'width:160px',
          },
          {
            name: 'lawNameAbb',
            field: 'lawNameAbb',
            label: '법령약칭명',
            align: 'left',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'promulgationDate',
            field: 'promulgationDate',
            label: '공포일자',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'revisiontypename',
            field: 'revisiontypename',
            label: '제개정구분명',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'competentMinistriesName',
            field: 'competentMinistriesName',
            label: '소관부처명',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'lawClassName',
            field: 'lawClassName',
            label: '법령구분명',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'enforcementDate',
            field: 'enforcementDate',
            label: '시행일자',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'lawTypeFlag',
            field: 'lawTypeFlag',
            label: '자법타법여부',
            align: 'center',
            sortable: false,
            style: 'width:60px',
          },
          {
            name: 'custom',
            field: 'custom',
            label: '법령상세링크',
            align: 'center',
            sortable: false,
            style: 'width:60px',
            type: 'custom'
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.law.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(props, col) {
      window.open('http://www.law.go.kr' + props.row.lawLink, '법규', 'width=1200, height=700');
    }
  }
};
</script>
